import { ListConfig, FilterField, FieldConfig } from '../shared/Types';
import * as Keys from '../shared/Keys';

export const RosterTypes: ListConfig[] = [
  {
    name: 'Program Roster',
    dispcols: [
      Keys.First,
      Keys.Last,
      Keys.Gender,
      Keys.RowingAge,
      Keys.School,
      Keys.HSGrad,
      Keys.Phone,
      Keys.Email,
      Keys.ParentEmail,
    ],
    editcols: [Keys.Status, Keys.Team, Keys.TimeSlot, Keys.Cox],
  },
  {
    name: 'Em Contacts',
    dispcols: [
      Keys.First,
      Keys.Last,
      Keys.Phone,
      Keys.ParentName,
      Keys.ParentPhone,
      Keys.SecondaryName,
      Keys.SecondaryPhone,
      Keys.ParentEmail,
      Keys.SecondaryEmail,
    ],
    editcols: [Keys.Status, Keys.Team, Keys.Cox],
  },
  {
    name: 'Attendance',
    borders: true,
    dispcols: [Keys.First, Keys.Last, '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
    editcols: [Keys.Status, Keys.Team, Keys.Cox],
  },
  {
    name: 'Medical',
    dispcols: [Keys.First, Keys.Last, Keys.Allergies, Keys.MedCond, Keys.Meds],
    editcols: [Keys.Allergies, Keys.MedCond, Keys.Meds],
  },
  {
    name: 'Waivers',
    dispcols: [
      Keys.First,
      Keys.Last,
      Keys.CovidWaiver,
      Keys.CovidVaccine,
      Keys.Waiver,
      Keys.USRMember,
      Keys.AthleteSafesport,
      Keys.ParentSafesport,
      Keys.CodeOfConduct,
    ],
    editcols: [
      Keys.CovidWaiver,
      Keys.CovidVaccine,
      Keys.Waiver,
      Keys.USRMember,
      Keys.AthleteSafesport,
      Keys.ParentSafesport,
      Keys.CodeOfConduct,
    ],
  },
  {
    name: 'Accounting',
    dispcols: [
      Keys.First,
      Keys.Last,
      Keys.Invoiced,
      Keys.Program,
      Keys.Balance,
      Keys.BalanceDate,
      Keys.QBOFirst,
      Keys.QBOLast,
      Keys.QboId,
    ],
    editcols: [Keys.Invoiced, Keys.QboId],
  },
  {
    name: 'Admin',
    dispcols: [
      Keys.First,
      Keys.Last,
      Keys.CreateTimestamp,
      // Keys.Program,
      // Keys.ProgramYear,
      // Keys.TimeSlot,
      Keys.Cox,
      Keys.Invoiced,
      Keys.CovidWaiver,
      Keys.Waiver,
      Keys.USRMember,
      Keys.CodeOfConduct,
      Keys.SwimTest,
      Keys.Physical,
      Keys.Volunteering,
    ],
    editcols: [
      Keys.First,
      Keys.Last,
      Keys.Cox,
      Keys.Invoiced,
      Keys.CovidWaiver,
      Keys.Waiver,
      Keys.USRMember,
      Keys.CodeOfConduct,
      Keys.SwimTest,
      Keys.Physical,
    ],
    editallcols: [
      '--Program Selection',
      Keys.Cox,
      Keys.ProgramYear,

      '--Athlete Details',
      Keys.First,
      Keys.Last,
      Keys.Gender,
      Keys.Email,
      Keys.Phone,
      Keys.Birthdate,
      Keys.Allergies,
      Keys.MedCond,
      Keys.Meds,
      Keys.School,
      Keys.OtherSchool,
      Keys.HSGrad,

      '--Athlete Address',
      Keys.Address1,
      Keys.Address2,
      Keys.City,
      Keys.State,
      Keys.Zip,
      '--Parent Contact',
      Keys.ParentFirst,
      Keys.ParentLast,
      Keys.ParentEmail,
      Keys.ParentPhone,

      '--Secondary Contact',
      Keys.SecondaryFirst,
      Keys.SecondaryLast,
      Keys.SecondaryEmail,
      Keys.SecondaryPhone,
      Keys.SecondaryRelation,
      Keys.SecondaryEmailList,

      '--Admin Fields',
      Keys.Invoiced,
      Keys.CovidWaiver,
      Keys.CovidVaccine,
      Keys.Waiver,
      Keys.USRMember,
      Keys.AthleteSafesport,
      Keys.ParentSafesport,
      Keys.CodeOfConduct,
      Keys.SwimTest,
      Keys.Physical,
    ],
  },
];

export const FilterFields: FilterField[] = [
  {
    title: 'Roster Type',
    key: '',
    settingKey: 'listTypes',
    uiType: 'select',
    compareMethod: 'equals',
    optionList: ['Program Roster', 'Em Contacts', 'Attendance', 'Medical', 'Waivers'],
    adminOptionList: ['Program Roster', 'Em Contacts', 'Attendance', 'Medical', 'Waivers', 'Accounting', 'Admin'],
  },
  {
    title: 'Year',
    key: Keys.ProgramYear,
    settingKey: 'years',
    uiType: 'select',
    compareMethod: 'equals',
    optionList: ['2022', '2021', '2020', '2019', '2018', '2017'],
  },
  {
    title: 'Season',
    key: Keys.Program,
    settingKey: 'seasons',
    uiType: 'checkbox',
    compareMethod: 'includes',
    optionList: ['Singles Practice', 'Spring', 'Summer', 'Fall', 'Winter'],
    autoList: false,
  },
  {
    title: 'Team Genders',
    key: Keys.Gender,
    settingKey: 'genders',
    uiType: 'checkbox',
    compareMethod: 'gender',
    optionList: ['Female', 'Male'],
  },
  // {
  //   title: 'Time Slot',
  //   key: Keys.TimeSlot,
  //   settingKey: 'timeslots',
  //   uiType: 'checkbox',
  //   compareMethod: 'equals',
  //   optionList: ['Session 1', 'Session 2', 'Session 3', 'Session 4', 'WaitList'],
  // },
  {
    title: 'Team',
    key: Keys.Team,
    settingKey: 'teams',
    uiType: 'checkboxmulti',
    compareMethod: 'includesany',
    optionList: ['Novice', 'Varsity', 'Middle School'],
  },
  {
    title: 'Status',
    key: Keys.Status,
    settingKey: 'statuses',
    uiType: 'checkbox',
    compareMethod: 'equals',
    defaultValue: 'Active',
    optionList: ['Active', 'Withdrew', 'Deleted'],
  },
];
export const JuniorFieldConfig: FieldConfig = {
  [Keys.TimeSlot]: {
    title: 'Time Slot',
    type: 'enum',
    label: 'Time',
    enums: [
      { key: 'Session 1', title: 'Session 1' },
      { key: 'Session 2', title: 'Session 2' },
      { key: 'Session 3', title: 'Session 3' },
      { key: 'Session 4', title: 'Session 4' },
      { key: 'WaitList', title: 'Wait List' },
    ],
    src: Keys.RegList,
  },
};
