/* eslint-disable no-script-url */

import React, { useState, useMemo, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import PrintIcon from '@mui/icons-material/Print';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import EmailIcon from '@mui/icons-material/Email';
import EditIcon from '@mui/icons-material/Edit';
import ClearSearchIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';
import CallMergeIcon from '@mui/icons-material/CallMerge';
import { Box, Tooltip, TextField, InputAdornment, TableSortLabel, Checkbox, Link } from '@mui/material';
import { useDataList } from './hooks/UseDataList';
import { ArgMap, ListConfig, FilterField, EnumDef } from './shared/Types';
import DisplayFilter, { DisplayFilters } from './DisplayFilter';
import { useGlobalProperty } from './hooks/UseGlobalProperty';
import * as Keys from './shared/Keys';
import copy from 'clipboard-copy';
import useToast from './hooks/UseToast';
import clsx from 'clsx';
import { CurrencyFormatter, DefaultFieldConfig } from './data/FieldTypes';
import { useIsAdmin, useIsRosterOnly } from './hooks/UseUserState';
import { useRosterType, FilterFields } from './data/RosterTypes';
import FileSaver from 'file-saver';
import { useMergeAccount } from './MergeAccounts';
import { generateLGLExport } from './util/LGLExport';
import useAlert from './hooks/UseAlertDialog';
const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  red: {
    color: 'red',
  },
  formControl: {
    margin: theme.spacing(3),
  },
  print: {
    marginLeft: theme.spacing(3),
  },
  search: {
    marginLeft: theme.spacing(3),
  },
  bordercell: {
    border: 1,
    borderStyle: 'solid',
    borderColor: '#000',
    minWidth: '4em',
  },
  cell: {
    paddingRight: '0.5em',
  },
  smallEmail: { fontSize: '1rem', marginRight: theme.spacing(1) },
  icon: {
    display: 'inlineFlex',
    alignItems: 'center',
    justifyContent: 'center',
    verticalAlign: 'middle',
  },
}));

const toString = (v: any) => {
  return v === undefined ? '' : String(v);
};
export default function RosterList() {
  const classes = useStyles();
  const [rosterType] = useRosterType();
  const { list: registrationList, remove: removeRegistration } = useDataList<ArgMap>(`${rosterType}/registrations`);
  const { list: memberList } = useDataList<ArgMap>(`${rosterType}/members`);
  const { list: rosterTypes } = useDataList<ListConfig>(`${rosterType}/rostertypes`);
  const [search, setSearch] = useState('');
  const { success } = useToast();
  const [orderBy, setOrderBy] = useState(Keys.Last);
  const [order, setOrder] = useState<'desc' | 'asc'>('asc');
  const [selected, setSelected] = React.useState([] as string[]);
  const [displayFilters, setDisplayFilters] = useState<DisplayFilters>({
    years: { '2022': true },
    seasons: { Spring: true },
    teams: {},
    programs: {},
    timeslots: {},
    genders: { Female: false, Male: false },
    statuses: { Active: true },
    listTypes: { 'Program Roster': true },
  });
  const [, setEditConfig] = useGlobalProperty('EditRecord');
  const [, setMergeConfig] = useMergeAccount();
  const [isAdmin, isSuperAdmin] = useIsAdmin();
  const [rosterOnly] = useIsRosterOnly();
  const [showDups, setShowDups] = useState(false);
  const filterFields: FilterField[] = FilterFields[rosterType] || [];
  const setAlert = useAlert();

  if (rosterOnly) {
    const rosterField = filterFields.find((entry) => entry.title === 'Roster Type');
    if (rosterField) {
      rosterField.optionList = ['Program Roster'];
    }
  }
  useEffect(() => {
    // Clear selections if filter changes
    setSelected([]);
  }, [displayFilters]);

  const membersById = useMemo(() => {
    const mById: ArgMap<ArgMap> = {};
    memberList.forEach((member) => (mById[member[Keys.Id]] = member));
    return mById;
  }, [memberList]);
  // ===================== End of hook use ==============================

  const programs: { [key: string]: EnumDef } = {};
  registrationList.forEach((row) => {
    let title = (row[Keys.Program] as string).replace(/^[ 0-9]*/, '').replace(/ *\(.*/, '');
    row[Keys.Program] = title;
    const program = title.replace(/[/ ].*/, '').trim();
    programs[program] = { key: title, title: title };
  });
  const programList = Object.keys(programs).sort();
  const dataChoices = { [Keys.Program]: programList };
  const auxFieldConfig = {
    [Keys.Program]: {
      title: 'Program',
      type: 'enum',
      label: 'Program',
      enums: programList.map((key) => programs[key]),
      src: Keys.RegList,
    },
  };

  if (registrationList.length === 0 || memberList.length === 0) {
    return null;
  }
  const numSelected = selected.length;

  const { listTypes } = displayFilters;
  const listType = Object.keys(listTypes || [])[0] || '';

  const listConfig =
    rosterTypes.find((item) => item.name === listType) || ({ name: '', dispcols: [], editcols: [] } as ListConfig);
  const cellclasses = listConfig.borders
    ? { body: classes.bordercell, head: classes.bordercell }
    : { body: classes.cell };

  let rows: ArgMap<any>[] = [...registrationList];
  if (showDups) {
    const members = [...memberList];
    const sorted = members.sort((a, b) => a.Last.localeCompare(b.Last));
    const counts: { [key: string]: number } = {};
    sorted.forEach((m) => (counts[m.Last] = (counts[m.Last] || 0) + 1));
    const dups: string[] = [];
    sorted.forEach((m) => {
      if (counts[m.Last] > 1) {
        //dups.push(`${m.Last}, ${m.First} ${m.Birthdate}`);
        dups.push(m[Keys.Id]);
      }
    });
    const dupsMarked: ArgMap = {};
    rows = rows.filter((row) => {
      const memberId = row[Keys.MemberId];
      const retain = !dupsMarked[memberId] && dups.includes(memberId);
      if (retain) {
        dupsMarked[memberId] = true;
      }
      return retain;
    });
  }

  // Don't apply any filters if showDups.
  rows = showDups
    ? rows
    : rows.filter((row) => {
        let found = true;
        // For each filter field, apply compare criteria
        for (let i = 0; i < filterFields.length; i++) {
          const field = filterFields[i];
          if (!field.key) {
            // No key, cannot extract data.  Could be listType.
            continue;
          }
          const settings = displayFilters[field.settingKey] || {};
          let count = 0;
          for (const name in settings) {
            if (settings[name]) {
              count++;
            }
          }
          if (count === 0) {
            // none specified, match all
            continue;
          }
          const fieldValue = row[field.key] || field.defaultValue || '';
          const any = field.compareMethod.includes('any');
          found = !any; // default false any match ok, true otherwise
          for (const name in settings) {
            if (!settings[name]) {
              continue; // setting === false, ignore
            }

            switch (field.compareMethod) {
              case 'equals':
                found = found && fieldValue === name;
                break;
              case 'equalsany':
                found = found || fieldValue === name;
                break;
              case 'includes':
                found = found && fieldValue.includes(name);
                break;
              case 'includesany':
                found = found || fieldValue.includes(name);
                break;
              case 'gender':
                {
                  const member = membersById[row[Keys.MemberId]];
                  if (!member) {
                    return false;
                  }
                  // If cox status is set, use it for gender determination to sort with the proper team
                  const cox = row[Keys.Cox] || 'Rower';
                  if (cox !== 'Rower') {
                    found = found && cox.includes(name);
                  } else {
                    found = found && (member[Keys.Gender] || '').indexOf(name) >= 0;
                  }
                }
                break;
            }
          }

          if (!found) {
            break;
          }
        }
        if (!found) {
          return found;
        }

        return found;
      });

  const handleSearchChange = (event: any) => {
    setSearch(event.target.value);
  };

  const onClearSearch = () => {
    setSearch('');
  };

  const onRequestSort = (event: any, property: string) => {
    if (orderBy === property) {
      setOrder(order === 'asc' ? 'desc' : 'asc');
    } else {
      setOrderBy(property);
    }
  };

  const onSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((row) => row[Keys.Id]);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;
  const handleRowClick = (event: any, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [] as string[];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const onExportClick = (e: any) => {
    e.stopPropagation();
    const listType = Object.keys(listTypes || [])[0] || '';
    const exportRaw = Boolean(e.shiftKey);
    const exportLgl = Boolean(e.ctrlKey && e.shiftKey);
    const exportCols = exportRaw ? Object.keys(rows[0]) : listConfig.dispcols;
    let blob: Blob;
    if (exportLgl) {
      const csv = generateLGLExport(rosterType, rows);
      blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    } else {
      const headers = exportCols
        .map((col, i) => `"${exportRaw ? col : (DefaultFieldConfig[col] && DefaultFieldConfig[col].title) || col}"`)
        .join(',');
      const csv = rows
        .map((row, rowIndex) => {
          return exportCols
            .map((col, i) => {
              const val = toString(row[col]);
              return `"${val.replace(/"/g, '""')}"`;
            })
            .join(',');
        })
        .join('\n');
      blob = new Blob([`${headers}\n${csv}`], { type: 'text/csv;charset=utf-8' });
    }
    FileSaver.saveAs(blob, `${rosterType}-${listType}.csv`.replace(/ /g, ''));
  };

  const createSortHandler = (property: string) => (event: any) => {
    onRequestSort(event, property);
  };

  const getAllFields = (row: ArgMap) => {
    const member = membersById[row[Keys.MemberId]];
    if (!member) {
      console.log('Unable to locate member for ' + JSON.stringify(row));
      return row;
    }
    const result = { ...member };
    for (const key in row) {
      const config = DefaultFieldConfig[key];
      if (config && config.src === Keys.RegList) {
        result[key] = row[key];
      }
    }
    // Synthetic fields
    try {
      const currentYear = new Date().getFullYear();
      const bd = new Date(member[Keys.Birthdate] + 'T00:00:00');
      result[Keys.RowingAge] = String(currentYear - bd.getFullYear());
    } catch (e) {
      result[Keys.RowingAge] = String(0);
    }
    result[Keys.ParentName] = `${member[Keys.ParentFirst]} ${member[Keys.ParentLast]}`;
    result[Keys.SecondaryName] = `${member[Keys.SecondaryFirst]} ${member[Keys.SecondaryLast]}`;
    result[Keys.EmName] = `${member[Keys.EmFirst]} ${member[Keys.EmLast]}`;

    result[Keys.Id] = row[Keys.Id]; // always retain id
    return result;
  };

  // merge fields from member and registration lists
  rows = rows.map((row) => getAllFields(row));

  // If search entered, prune list by looking at display column values
  if (search) {
    const lowerSearch = search.toLowerCase();
    rows = rows.filter((row) =>
      listConfig.dispcols.find((key) =>
        String(row[key] || '')
          .toLowerCase()
          .includes(lowerSearch)
      )
    );
  }
  const rowCount = rows.length;
  const onHeaderClick = (key: string) => () => {
    if (key.toLowerCase().includes('email')) {
      let allowed =
        numSelected === 0 || numSelected === rowCount ? rows : rows.filter((row) => selected.includes(row[Keys.Id]));
      const initialCount = allowed.length;
      if (key === Keys.SecondaryEmail) {
        allowed = allowed.filter((row) => row[Keys.SecondaryEmailList]);
      }
      // Pull out emails and take only unique items and convert to comma sep list removing empties
      const emails = allowed.map((row) => row[key]).filter((v, i, a) => a.indexOf(v) === i);
      const list = emails.join(',').replace(/,,/g, ',');
      copy(list);
      success(
        `${emails.length}/${initialCount} Emails copied to clipboard. (${allowed.length - emails.length} dups, ${
          initialCount - allowed.length
        } excluded)`
      );
    }
  };

  const onEditRow = (row: ArgMap) => (e: React.MouseEvent<Element, MouseEvent>) => {
    if (isAdmin) {
      if (e.shiftKey) {
        e.preventDefault();
        setAlert({
          title: 'Delete Registration',
          message: `OK to delete entry for ${row.Last}, ${row.First}?`,
          onLeft: () => {},
          onRight: async () => {
            await removeRegistration(row[Keys.Id]);
            success(`Record Deleted`);
          },
          onRightText: 'Delete',
          onLeftText: 'Cancel',
        });
      } else {
        setEditConfig({ listConfig, open: true, value: row, auxFieldConfig: auxFieldConfig });
      }
    }
  };

  // sort fields
  if (orderBy) {
    rows = rows.sort((a, b) => {
      const aval = toString(a[orderBy]);
      const bval = toString(b[orderBy]);
      let cmp = aval.localeCompare(bval, undefined, {
        numeric: true,
        sensitivity: 'base',
      });
      if (order === 'desc') cmp = cmp * -1;
      return cmp;
    });
  }

  const onMergeAccounts = () => {
    if (!showDups) {
      setShowDups(true);
      return;
    }
    const selectedRows = rows.filter((row) => selected.includes(row[Keys.Id]));
    setMergeConfig({ open: true, rows: selectedRows });
  };

  let printRow = 0;

  return (
    <React.Fragment>
      <Box displayPrint="none">
        <DisplayFilter
          choices={dataChoices}
          values={displayFilters}
          filterFields={filterFields}
          onChange={(v) => {
            setDisplayFilters({ ...v });
          }}
        />
      </Box>
      <Box display="flex" flexDirection="row" displayPrint="none">
        <Title>Roster</Title>
        <Tooltip title="Print Roster" placement="bottom">
          <PrintIcon className={classes.print} onClick={() => window.print()} />
        </Tooltip>
        <Tooltip title="Export Roster" placement="bottom">
          <SaveAltIcon className={classes.print} onClick={onExportClick} onContextMenu={onExportClick} />
        </Tooltip>
        {isSuperAdmin && (
          <Tooltip title="Merge Accounts" placement="bottom">
            <CallMergeIcon className={`${classes.print} ${showDups ? classes.red : ''}`} onClick={onMergeAccounts} />
          </Tooltip>
        )}
        <TextField
          variant="standard"
          className={classes.search}
          value={search}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="Clear Search" placement="bottom">
                  <ClearSearchIcon style={{ color: '#888' }} onClick={onClearSearch} />
                </Tooltip>
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox" className="noprint">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ 'aria-label': 'Select all desserts' }}
              />
            </TableCell>
            <TableCell padding="none" className="noprint" classes={cellclasses}>
              Action
            </TableCell>
            <TableCell padding="none" classes={cellclasses}>
              <Box display="flex" flexDirection="row" displayPrint="none">{`Count (${rows.length})`}</Box>
            </TableCell>
            {listConfig.dispcols.map((col, i) => (
              <TableCell padding="none" key={i} classes={cellclasses} sortDirection={orderBy === col ? order : false}>
                {col.toLowerCase().includes('email') && (
                  <Tooltip title="Copy emails to clipboard" placement="bottom">
                    <EmailIcon
                      className={clsx(classes.icon, 'noprint')}
                      fontSize="small"
                      classes={{ fontSizeSmall: classes.smallEmail }}
                      onClick={onHeaderClick(col)}
                    />
                  </Tooltip>
                )}
                <TableSortLabel active={orderBy === col} direction={order} onClick={createSortHandler(col)}>
                  {(DefaultFieldConfig[col] && DefaultFieldConfig[col].title) || col}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIndex) => {
            const rowId = row[Keys.Id];
            const isItemSelected = isSelected(rowId);
            const labelId = `enhanced-table-checkbox-${rowId}`;
            // hide row when printing
            const noprint = !isItemSelected && numSelected !== 0 && numSelected !== rowCount;
            const rowClassName = noprint ? 'noprint' : '';
            if (!noprint) {
              printRow = printRow + 1;
            }
            const onEditRowClicked = onEditRow(row);
            return (
              <TableRow
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={rowId}
                selected={isItemSelected}
                className={rowClassName}
                onDoubleClick={onEditRowClicked}
              >
                <TableCell
                  padding="checkbox"
                  className="noprint"
                  onClick={(event: any) => handleRowClick(event, rowId)}
                >
                  <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                </TableCell>
                <TableCell padding="none" className="noprint" classes={cellclasses}>
                  {isAdmin && <EditIcon onClick={onEditRowClicked} />}
                </TableCell>

                {/* <Box display="none" displayPrint="flex"> */}
                <TableCell padding="none" className="noscreen" classes={cellclasses}>
                  {printRow}
                </TableCell>
                {/* </Box> */}
                <TableCell padding="none" className="noprint" classes={cellclasses}>
                  {rowIndex + 1}
                </TableCell>
                {listConfig.dispcols.map((col, i) => {
                  const config = DefaultFieldConfig[col] || {};
                  let val: any = toString(row[col]);
                  if (config.type === 'timestamp') {
                    let d = new Date(Number(val));
                    const valid = d instanceof Date && !!d.getDate();
                    if (!valid) {
                      d = new Date(String(val));
                    }
                    val = d.toLocaleString('en-CA');
                  } else if (config.type === 'date') {
                    if ((typeof val === 'string' && val.includes('T')) || typeof val === 'number') {
                      const d = new Date(val);
                      val = d.toLocaleDateString('en-CA');
                    }
                  } else if (config.type === 'checkbox') {
                    // val = val === 'true' ? 'X' : '';
                    val = <Checkbox checked={val === 'true'} />;
                  } else if (config.type === 'currency') {
                    val = CurrencyFormatter.format(Number(val));
                  }
                  if (config.link) {
                    // eslint-disable-next-line no-template-curly-in-string
                    const link = config.link.replace('${value}', String(val));
                    val = (
                      <Link color="primary" href={link} target="_blank" rel="noopener">
                        {val}
                      </Link>
                    );
                  }
                  return (
                    <TableCell padding="none" key={i} classes={cellclasses}>
                      {val}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
