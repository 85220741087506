import { ListConfig, FilterField, FieldConfig } from '../shared/Types';
import * as Keys from '../shared/Keys';

export const RosterTypes: ListConfig[] = [
  {
    name: 'Program Roster',
    dispcols: [
      Keys.First,
      Keys.Last,
      Keys.RowingAge,
      Keys.TimeSlot,
      Keys.ProgramYear,
      Keys.Program,
      Keys.Gender,
      Keys.Phone,
      Keys.Email,
    ],
    editcols: [Keys.Status, Keys.TimeSlot, Keys.Program],
  },
  {
    name: 'Em Contacts',
    dispcols: [Keys.First, Keys.Last, Keys.Phone, Keys.EmName, Keys.EmPhone, Keys.EmRelationship],
    editcols: [Keys.Status, Keys.TimeSlot],
  },
  {
    name: 'Medical',
    dispcols: [Keys.First, Keys.Last, Keys.Allergies, Keys.MedCond, Keys.Meds],
    editcols: [Keys.Allergies, Keys.MedCond, Keys.Meds],
  },
  {
    name: 'Waivers',
    dispcols: [
      Keys.First,
      Keys.Last,
      Keys.CovidWaiver,
      Keys.CovidVaccine,
      Keys.Waiver,
      Keys.USRMember,
      Keys.AthleteSafesport,
      Keys.ParentSafesport,
      Keys.CodeOfConduct,
    ],
    editcols: [
      Keys.CovidWaiver,
      Keys.CovidVaccine,
      Keys.Waiver,
      Keys.USRMember,
      Keys.AthleteSafesport,
      Keys.ParentSafesport,
      Keys.CodeOfConduct,
    ],
  },
  {
    name: 'Accounting',
    dispcols: [
      Keys.First,
      Keys.Last,
      Keys.Invoiced,
      Keys.Program,
      Keys.Balance,
      Keys.BalanceDate,
      Keys.QBOFirst,
      Keys.QBOLast,
      Keys.QboId,
    ],
    editcols: [Keys.Invoiced, Keys.QboId, Keys.MemberId],
  },
  {
    name: 'Admin',
    dispcols: [
      Keys.First,
      Keys.Last,
      Keys.Program,
      Keys.Invoiced,
      Keys.CovidWaiver,
      Keys.Waiver,
      Keys.USRMember,
      Keys.AthleteSafesport,
      Keys.SwimTest,
      Keys.Physical,
      Keys.Volunteering,
    ],
    editcols: [
      Keys.First,
      Keys.Last,
      Keys.Invoiced,
      Keys.CovidWaiver,
      Keys.Waiver,
      Keys.USRMember,
      Keys.AthleteSafesport,
      Keys.CodeOfConduct,
      Keys.SwimTest,
      Keys.Physical,
    ],
    editallcols: [
      '--Program Selection',
      Keys.TimeSlot,
      Keys.ProgramYear,
      Keys.Program,

      '--Athlete Details',
      Keys.First,
      Keys.Last,
      Keys.Gender,
      Keys.Email,
      Keys.Phone,
      Keys.Birthdate,
      Keys.Allergies,
      Keys.MedCond,
      Keys.Meds,
      Keys.Volunteering,

      '--Athlete Address',
      Keys.Address1,
      Keys.Address2,
      Keys.City,
      Keys.State,
      Keys.Zip,
      '--Emergency Contact',
      Keys.EmFirst,
      Keys.EmLast,
      Keys.EmEmail,
      Keys.EmRelationship,

      '--Admin Fields',
      Keys.Invoiced,
      Keys.CovidWaiver,
      Keys.CovidVaccine,
      Keys.Waiver,
      Keys.USRMember,
      Keys.AthleteSafesport,
      Keys.CodeOfConduct,
      Keys.SwimTest,
      Keys.Physical,
    ],
  },
];

export const FilterFields: FilterField[] = [
  {
    title: 'Roster Type',
    key: '',
    settingKey: 'listTypes',
    uiType: 'select',
    compareMethod: 'equals',
    optionList: ['Program Roster', 'Em Contacts', 'Medical', 'Waivers'],
    adminOptionList: ['Program Roster', 'Em Contacts', 'Medical', 'Waivers', 'Accounting', 'Admin'],
  },
  {
    title: 'Year',
    key: Keys.ProgramYear,
    settingKey: 'years',
    uiType: 'select',
    compareMethod: 'equals',
    optionList: ['2022', '2021', '2020', '2019', '2018', '2017'],
  },
  {
    title: 'Time Slot',
    key: Keys.TimeSlot,
    settingKey: 'timeslots',
    uiType: 'checkbox',
    compareMethod: 'equals',
    optionList: ['Morning', 'MidMorning', 'LateMorning', 'Evening'],
  },
  {
    title: 'Team Genders',
    key: Keys.Gender,
    settingKey: 'genders',
    uiType: 'checkbox',
    compareMethod: 'gender',
    optionList: ['Female', 'Male'],
  },
  {
    title: 'Program',
    key: Keys.Program,
    settingKey: 'programs',
    uiType: 'checkboxmulti',
    compareMethod: 'includesany',
    optionList: ['Annual', 'Quarterly', 'Monthly', 'Single', 'Double', 'Other', 'Boat'],
    autoList: true,
  },
  {
    title: 'Status',
    key: Keys.Status,
    settingKey: 'statuses',
    uiType: 'checkbox',
    compareMethod: 'equals',
    defaultValue: 'Active',
    optionList: ['Active', 'Withdrew', 'Deleted'],
  },
];

export const MastersFieldConfig: FieldConfig = {};
