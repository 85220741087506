import { initializeApp } from 'firebase/app';
import firebase from 'firebase/compat/app';

export const firebaseConfig = {
  apiKey: 'AIzaSyCkLcJS8aZoiNvXYbshmMea_8GmyHC2VjA',
  authDomain: 'era-rosters-dev.firebaseapp.com',
  databaseURL: 'https://era-rosters-dev.firebaseio.com',
  projectId: 'era-rosters-dev',
  storageBucket: 'era-rosters-dev.appspot.com',
  messagingSenderId: '576895404605',
  appId: '1:576895404605:web:e9d9a74d6c24436a',
};

// V9+ init
export const app = initializeApp(firebaseConfig);
// V8 compat init
firebase.initializeApp(firebaseConfig);
